// React Components
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import dayjs from 'dayjs';

// Material UI Components
import Alert from '@mui/material/Alert';
import AlertTitle from '@mui/material/AlertTitle';
import Button from '@mui/material/Button';
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import Grid from '@mui/material/Grid'; 
import Typography from '@mui/material/Typography';
import RefreshIcon from '@mui/icons-material/Refresh';
import GppGoodIcon from '@mui/icons-material/GppGood';

// Audit Vault Components
import ProgressBar from "../common/ProgressBar";
import AccessDenied from "../common/AccessDenied";
import ServiceIsDown from "../common/ServiceIsDown";

// Audit Vault Utilities
import { verifyUserCanAccessTenantData } from "../../utilities/common-user-utils";
import { formatInvoiceDate } from '../../utilities/common-date-utils';
import { getTenantById, getHistoricalImportAuditStats } from "../../utilities/common-tenant";


export default function TenantHistory(props) 
{
    const navigate = useNavigate();
    const { user, company, tenantId } = props;

    const [loading, setLoading] = useState(true);
    const [loadingMsg, setLoadingMsg] = useState("Loading ...");
    const [accessDenied, setAccessDenied] = useState(false);
    const [serviceIsDownError, setServiceIsDownError] = useState(false);
    const [tenant, setTenant] = useState('');
    const [tenantHistoricalImportStats, setTenantHistoricalImportStats] = useState("");

    const [removeAccessInstructionsVisible, setRemoveAccessInstructionsVisible] = useState(false);  // Hide the remove access instructions by default.

    const handleOpenHistoricalReport = async () => {
        navigate(`/Reports/historical-audit-history/${tenantId}`);
    }

    const toggleRemoveAccessInstructions = (event) => {      
        event.preventDefault(); // Prevent the default behavior of the anchor tag.
        setRemoveAccessInstructionsVisible(!removeAccessInstructionsVisible);
    }

    const getFirstAuditCompleteDate = () =>
    {
        if (company && company.tenantList)
        {
            for (var x=0; x <= company.tenantList.length; x++)
            {
                if (company.tenantList[x].id == tenantId)
                    return formatInvoiceDate(company.tenantList[x].firstAuditImportCompleteDate);
            }
        }
    }

    const getFirstAuditStartDate = () =>
    {
        if (company && company.tenantList)
        {
            for (var x=0; x <= company.tenantList.length; x++)
            {
                if (company.tenantList[x].id == tenantId)
                    return formatInvoiceDate(dayjs(company.tenantList[x].firstAuditImportStartDate).add(-7, 'day'));
                
            }
        }
    }

    const handlePayNow = async () => {
    
        navigate('/mybilling');
    }

    const handleImportStatsRefresh = async () => {
    
        try
        {
            var statsResponse = await getHistoricalImportAuditStats(tenantId);
            if (statsResponse)
            {
                console.log(statsResponse)
                setTenantHistoricalImportStats(statsResponse);
            }
        }
        catch (e)
        {
            console.log("TenantHistory : handleImportStatsRefresh");
            console.log(e);
        }
        finally{
            
        }
    }

    async function fetchData() {
        if(loading && user)
        {
            try{                
                if (verifyUserCanAccessTenantData(user, tenantId))
                {
                    var response = await getTenantById(tenantId);
                    if (response) 
                    {
                        setTenant(response);

                        if (response.historicalAuditImportStage >= 3)
                        {
                            // get the status of the import
                            var statsResponse = await getHistoricalImportAuditStats(tenantId);
                            if (statsResponse)
                            {
                                setTenantHistoricalImportStats(statsResponse);
                            }
                        }

                    }
                }
                else
                {
                    setAccessDenied(true);
                }
            }
            catch (e)
            {
                setServiceIsDownError(true);
                console.log("ERROR: TenantHistory:fetchData");
                console.log(e);
            }
            finally{
                setLoading(false);
            }
        }
    }

    useEffect(() => {
        fetchData();
    }, []);

    if (accessDenied)
    {
        return(<AccessDenied></AccessDenied>)
    }
    
    if (serviceIsDownError) {
        return (<><ServiceIsDown></ServiceIsDown></>);
    }


    if (tenant)
    {
        return (
            <><Card sx={{ width: 800}} variant="outlined">
                <CardContent>
                    <ProgressBar message={loadingMsg} loading={loading} />
                    {!loading && 
                    <>
                    <Typography component="div">Audit Vault for M365 is managing and securing your organization’s audit activities.  Below is a detailed account of when Audit Vault for M365 began managing your Microsoft 365 audit log history.  In addition if you haven’t already – you may pull in all-historical audit records that may exist in your M365 Tenant.</Typography>                    
                    <hr style={{ border: 'none', borderTop: '1px solid rgba(0, 0, 0, 0.2)' }} />
                    {company && tenant &&
                        <Alert severity="info">
                        <AlertTitle>Audit Vault Protection</AlertTitle>
                        <Typography component="div">
                        This certifies that your Microsoft 365 go-forward and new audit log history is being actively and securely retained and protected in Audit Vault for M365.
                        <Typography component="div"><br></br></Typography>
                        <Grid container spacing={0}>                            
                            <Grid item xs={8}><GppGoodIcon style={{ color: 'green' }} /> Audit Vault Enabled for your Tenant On:</Grid>
                            <Grid item xs={4}>{getFirstAuditCompleteDate()}</Grid>
                            <Grid item xs={8}><GppGoodIcon style={{ color: 'green' }} /> Audit Vault Data Protection Inclusive From *:</Grid>
                            <Grid item xs={4}>{getFirstAuditStartDate()} to Current</Grid>
                        </Grid>
                        </Typography> 
                        </Alert>
                    }
                    <hr style={{ border: 'none', borderTop: '1px solid rgba(0, 0, 0, 0.2)' }} />                 
                    <Typography component="div"><b>Audit Vault Historical Data</b></Typography>
                    <Typography component="div">Microsoft keeps your audit log data for only up to 180 days for E3 user licenses, and only up to 1 year for E5 user licenses.  After those dates your valuable audit data will be lost forever.  Audit Vault for M365 protects your organization and allows you to pull back all available historical audit data that may exist prior to when you first enabled Audit Vault for your Tenant.  Below you will find details on how to add this data to Audit Vault to preserve and protect your historical audit data that may exist for your Tenant.</Typography>
                    <Typography component="div"><br></br></Typography>
                    
                    {company && company.setupStageComplete < 6 && company.setupStageComplete !=4 && 
                        <>
                        <Typography component="div"><b>Import Historical Audit History</b></Typography>
                        <Typography component="div">You are currently in your free trial period.  Due to the large amount of processing required this function is not available during your free trial period.  If you wish to forgo your free trial period and access this functionality now, click the button below. Note: If you wish to proceed your account will be converted to fully paid access and you will be billed accordingly.</Typography>
                        <Typography component="div"><br></br></Typography>
                        <Typography component="div"><Button variant="contained" type="button" size='small' onClick={() => handlePayNow()}>Pay Now</Button></Typography>
                        <Typography component="div"><br></br></Typography>
                        </>
                    }

                    {company && (company.setupStageComplete >= 6 || company.setupStageComplete ==4) && tenant.historicalAuditImportStage == 0 &&
                        <>
                        <Typography component="div"><b>Configure Your Tenant to Import Historical Audit Records</b></Typography>
                        <Typography component="div">Start protecting and preserving your valuable historical audit information today!  If you would like us to check if you have historical data and start loading your available historical data click the button below.  Please note that this operation may take a few days to complete due to Microsoft throttling behavior.  You will be able to monitor the status once the process is kicked off. </Typography>
                        <Typography component="div"><br></br></Typography>
                        <Typography component="div"><Button variant="contained" type="button" size='small' onClick={() => navigate(`/tenant-historical-import/${tenantId}`)}>Historical Data Setup</Button></Typography>
                        <Typography component="div"><br></br></Typography>
                        </> 
                    }

                    {company && tenant.historicalAuditImportStage > 0 && tenant.historicalAuditImportStage < 3 &&
                        <>
                        <Typography component="div"><b>Continue Setup</b></Typography>
                        <Typography component="div">You're almost there!  Complete the setup of your tenant to import your historical audit history.</Typography>
                        <Typography component="div"><br></br></Typography>
                        <Typography component="div"><Button variant="contained" type="button" size='small' onClick={() => navigate(`/tenant-historical-import/${tenantId}`)}>Continue Setup</Button></Typography>
                        <Typography component="div"><br></br></Typography>
                        </> 
                    }

                    {company && tenant.historicalAuditImportStage == 3 &&
                        <>
                        <Typography component="div"><b>Import In Progress</b></Typography>
                        <Typography component="div">Your historical audit log history is being loaded. Please note that this operation may take a while (days to weeks) to complete due to Microsoft throttling behavior and is dependent on your audit data volume.</Typography>
                        <Typography component="div"><br></br></Typography>
                        <Typography component="div">
                        <Grid container spacing={0}>
                            <Grid item xs={6}>Historical Data Import Started On:</Grid>
                            <Grid item xs={6}>{formatInvoiceDate(tenant.historicalImportStartDate)}</Grid>
                            <Grid item xs={6}>Import Job Status: </Grid>
                            <Grid item xs={6}>
                            {tenantHistoricalImportStats &&
                                <>
                                Loading {tenantHistoricalImportStats.totalHistoricalImportJobsCompleted} of {tenantHistoricalImportStats.totalHistoricalImportJobs} ({((tenantHistoricalImportStats.totalHistoricalImportJobsCompleted/tenantHistoricalImportStats.totalHistoricalImportJobs)*100).toFixed(1)}%)
                                <Button type="submit" aria-label="search" onClick={() => handleImportStatsRefresh()}> <RefreshIcon /></Button>
                                </>
                            }
                            </Grid>
                        </Grid>
                        </Typography>
                        <Typography component="div"><br></br></Typography>
                        <Typography component="div"><Button variant="contained" type="button" size='small' onClick={()=> handleOpenHistoricalReport()}>View Progress</Button></Typography>                        
                        </> 
                    }

                    {company && tenant.historicalAuditImportStage == 4 &&
                        <>                        
                        <Typography component="div"><b>Historical Audit Import Completed</b></Typography>
                        <Typography component="div">Your historical audit log data was successfully vaulted from Microsoft 365.  Any available audit logs from Microsoft have been preserved here (Note: depending on your Microsoft licensing, some of your audit logs may have already been purged by Microsoft prior to starting or completing this operation).<br/>Below you may view your compliance certificate which proves your Microsoft 365 historical audit history was imported and is securely retained + preserved + protected in Audit Vault for M365.</Typography>
                        <br />
                        <a href="#instruction" onClick={toggleRemoveAccessInstructions}>
                            {removeAccessInstructionsVisible ? 'Hide Remove Historical Access Instructions' : 'Optional: Show Remove Historical Access Instructions'}
                        </a>
                        {
                            removeAccessInstructionsVisible &&
                            (
                                <>
                                <hr />
                                <Typography><b><a name="instruction">(Instructions to Remove Historical Access)</a></b></Typography>                        
                                <Typography>If you haven't already done so - when your historical import is complete, you can simply remove the access needed for Audit Vault application to run the historical import.<br/>Note: Removing the "Historical Audit History Importer" access is optional and you don't need to remove it.  We suggest it as part of the cleanup post import of your historical audit history.</Typography>
                                <Typography>All you need to do is simply: Log into your Tenants' Entra Admin Center and remove "ECM Insights : Historical Audit History Importer".</Typography>                                                                         
                                <hr />
                                </>
                            )
                        }
                        <Typography component="div"><br></br></Typography>
                        <Alert severity="info">
                        <AlertTitle>Historical Audit Vault Protection</AlertTitle>
                        <Typography component="div">
                        This certifies that your Microsoft 365 historical audit log history was imported and is securely retained and protected in Audit Vault for M365.
                        <Typography component="div"><br></br></Typography>
                        <Grid container spacing={0}>
                            <Grid item xs={8}><GppGoodIcon style={{ color: 'green' }} /> Historical Data Import Started On:</Grid>
                            <Grid item xs={4}>{formatInvoiceDate(tenant.historicalImportStartDate)}</Grid>
                            <Grid item xs={8}><GppGoodIcon style={{ color: 'green' }} /> Historical Data Import Completed On:</Grid>
                            <Grid item xs={4}>{formatInvoiceDate(tenant.historicalImportCompleteDate)}</Grid>
                            <Grid item xs={8}><GppGoodIcon style={{ color: 'green' }} /> Historical Data Protection Inclusive From: </Grid>
                            <Grid item xs={4}>{formatInvoiceDate(tenantHistoricalImportStats.jobDateEarliest)} to {formatInvoiceDate(tenantHistoricalImportStats.jobDateNewest)}</Grid>
                        </Grid>
                        <br />
                        <Button variant="contained" type="button" size='small' onClick={()=> handleOpenHistoricalReport()}>View Compliance Certificate</Button>
                        <br /><br />
                        </Typography>
                        </Alert>                                                                        
                        </> 
                    }
                    </>
                }
                
                </CardContent>
                <CardActions>
                </CardActions>
            </Card>
            </>
        );
    };

}