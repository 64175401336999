// React Components
import { useNavigate } from "react-router-dom";
import { useEffect, useState } from "react";
import PropTypes from 'prop-types';

// Material UI Components
import { Grid, Paper } from '@mui/material';
import Link from '@mui/material/Link';
import { Alert, AlertTitle } from '@mui/material';
import ManageSearchIcon from '@mui/icons-material/ManageSearch';
import TableContainer from '@mui/material/TableContainer';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableRow from '@mui/material/TableRow';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';

// Audit Vault Components
import CompanyNag from "../components/common/CompanyNag";
import TrialPeriodNag from "../components/common/TrialPeriodNag";
import AccessDenied from "../components/common/AccessDenied";
import ServiceIsDown from "../components/common/ServiceIsDown";
import ProgressBar from "../components/common/ProgressBar";
import DisplayBreadCrumb from "../components/common/DisplayBreadCrumb";
import DisplaySectionTitleAndDescription from "../components/common/DisplaySectionTitleAndDescription";
import AuditGrowth from "../components/reports/AuditGrowth";
import TenantDropDown from "../components/reports/TenantDropDown";
import PeriodDropDown from "../components/reports/PeriodDropDown";
import TeamsAllActivity from "../components/reports/TeamsAllActivity";
import ImageTeams from '../imgs/iconTeams.svg';
import TenantSetupRequired from "../components/tenant/TenantSetupRequired";

// Audit Vault Utilities
import { getCompanyByCompanyId } from "../utilities/common-company";
import { NumberBadge } from '../utilities/common-ui-utils';
import { REPORT_PERIOD_LIST, REPORT_ON_PERIOD_30DAYS, REPORT_ON_PERIOD_90DAYS, REPORT_ON_PERIOD_DEFAULT_DROPDOWNVALUE } from "../constants/constants-reportperiods";
import { formatAuditJobRunTimeDateServerTimeToGMT } from '../utilities/common-date-utils';
import { verifyUserHasTenantRolePermissions } from "../utilities/common-user-utils";
import { ROLE_CODE_TENANTREPORTREADER } from "../constants/constants-roles";
import { getHealthStatsByTenantId } from "../utilities/common-healthstats";


const theBreadcrumbPath = [
    { name: 'Home', link: '/' },
    { name: 'Reports', link: '/Reports/ReportsHome' },
    { name: 'Teams Reports', link: '/Reports/teams' },
];

const theSectionTitle = "Teams Reports Dashboard";
const theSectionDesc = "Microsoft Teams audit logs enable you to capture and log activities to help organizations monitor, track, and ensure compliance with their communication and collaboration practices. These logs provide detailed insights into various activities within Teams including message, meeting, and user + admin related activities.";
const isAdminPage = false;


function ReportsTeams(props) {

    const { user, openCompanyNag, setOpenCompanyNag } = props;

    // Component Constants
    const [company, setCompany] = useState("");
    const [tenantHealthStats, setTenantHealthStats] = useState("");
    const [focusTenantId, setFocusTenantId] = useState("");
    const [focusPeriodId, setFocusPeriodId] = useState(REPORT_ON_PERIOD_DEFAULT_DROPDOWNVALUE);
    const [accessDenied, setAccessDenied] = useState(false);

    const [loading, setLoading] = useState(true);
    const [serviceIsDownError, setServiceIsDownError] = useState(false);
    const navigate = useNavigate();

    const [selectedTenantOption, setSelectedTenantOption] = useState('');
    const [selectedPeriodOption, setSelectedPeriodOption] = useState('');
    const [tabValue, setTabValue] = useState(0);

    // Component Functions
    async function fetchTenantHealthStats(theTenantId) {
        setLoading(true);
        try {

            // Check if current user has Report Reader access to the specified tenant.
            var checkReportAccessForTenant = verifyUserHasTenantRolePermissions(user, theTenantId, ROLE_CODE_TENANTREPORTREADER);
            if (!user || !checkReportAccessForTenant) {
                setAccessDenied(true);
            }
            else {
                setAccessDenied(false);
            }

            // We attempt to load the tenant health stats (which lets us see if the tenant is pending still etc).
            var response2 = await getHealthStatsByTenantId(theTenantId, 5, user);
            if (response2) {
                setTenantHealthStats(response2);
            }

        }
        catch (e) {
            console.log("ERROR: ReportTeams.fetchTenantHealthStats");
            console.log(e)
            setServiceIsDownError(true);
        }
        finally {
            setLoading(false);
        }
    }

    useEffect(() => {
        async function fetchCompanyData() {

            try {
                if (user && user.companyId) {
                    var response1 = await getCompanyByCompanyId(user.companyId, setServiceIsDownError);
                    if (response1) {

                        setCompany(response1);
                        setSelectedTenantOption(response1.tenantList[0]);

                        if (response1.tenantList && response1.tenantList.length > 0) {
                            fetchTenantHealthStats(response1.tenantList[0].id);
                            setFocusTenantId(response1.tenantList[0].id);
                        }
                        // Set to the first item in the list.
                        setSelectedPeriodOption(REPORT_PERIOD_LIST[REPORT_ON_PERIOD_DEFAULT_DROPDOWNVALUE]);
                    }

                }
                setLoading(false);
            }
            catch (e) {
                console.log("ERROR: ReportTeamsfetchCompanyData");
                console.log(e)
                setServiceIsDownError(true);
            }
            finally {
                setLoading(false);
            }
        }

        fetchCompanyData();
    }, []);


    // Tab panel.
    function TabPanel(props) {
        const { children, value, index, ...other } = props;

        return (
            <div
                role="tabpanel"
                hidden={value !== index}
                id={`simple-tabpanel-${index}`}
                aria-labelledby={`simple-tab-${index}`}
                {...other}
            >
                {value === index && (
                    <Box sx={{ p: 3 }}>
                        <Typography>{children}</Typography>
                    </Box>
                )}
            </div>
        );
    }

    TabPanel.propTypes = {
        children: PropTypes.node,
        value: PropTypes.number.isRequired,
        index: PropTypes.number.isRequired,
    };

    function a11yProps(index) {
        return {
            id: `simple-tab-${index}`,
            'aria-controls': `simple-tabpanel-${index}`,
        };
    }

    const handleTabChange = (event, newValue) => {
        setTabValue(newValue);
    };


    // Event handlers for drop down lists.
    const handleTenantOptionChange = (event) => {
        setSelectedTenantOption(event.target.value);
        fetchTenantHealthStats(event.target.value.id);
        setFocusTenantId(event.target.value.id);
    };
    const handlePeriodOptionChange = (event) => {
        setSelectedPeriodOption(event.target.value);
        setFocusPeriodId(event.target.value.id);
    };


    // Component UI
    if (loading) {
        console.log("Loading Data ....")
        return (<ProgressBar message="Loading ..." loading={loading} />);
    }

    if (serviceIsDownError) {
        return (<><ServiceIsDown></ServiceIsDown></>);
    }

    if (!accessDenied && selectedTenantOption && !selectedTenantOption.logTeamsAudit) {
        return (<Alert severity="info"><AlertTitle>Teams Audit Logging is not enabled for this tenant.</AlertTitle>If you would like this feature, please enable Preserve Teams Audit Logs under the General Audit Logs by editing your Tenant settings (or <Link onClick={() => navigate(`/Support/SupportHome`)} component="button">Contact your Administrator</Link>).</Alert>)
    }

    return (
        <>
            <TrialPeriodNag company={company}></TrialPeriodNag>
            <DisplayBreadCrumb paths={theBreadcrumbPath} />
            <div style={{ display: 'flex' }}>
                <div style={{ flex: 1, textAlign: 'left', alignSelf: 'flex-start' }}>
                    <DisplaySectionTitleAndDescription sectionTitle={theSectionTitle} sectionDescription={theSectionDesc} isAdminPage={isAdminPage} />
                </div>
                <div style={{ width: '75px', textAlign: 'left', alignSelf: 'flex-start' }}>
                    <br /><img src={ImageTeams} alt="Teams Reports" />
                </div>
            </div>
            <CompanyNag company={company} openCompanyNag={openCompanyNag} setOpenCompanyNag={setOpenCompanyNag} />
            <div>
                <Grid container spacing={2}>
                    {
                        accessDenied &&
                        <Grid sx={{ minWidth: 500, width: "100%", padding: 2 }}><AccessDenied /></Grid>
                    }
                    {!selectedTenantOption &&
                        (
                            <Grid sx={{ minWidth: 500, width: "100%", padding: 2 }}>
                                <Alert severity="error">
                                    <AlertTitle>Your Company has not yet completed the setup process or you have not yet specified a valid Tenant.</AlertTitle>
                                </Alert>
                            </Grid>)
                    }
                    {tenantHealthStats.status == 0 &&
                        (
                            <Grid sx={{ minWidth: 500, width: "100%", padding: 2 }}>
                                <TenantSetupRequired />
                            </Grid>
                        )
                    }
                    {selectedTenantOption &&
                        (
                            <Grid container sx={{ minWidth: 500, width: "100%", paddingX: 2, paddingY: 0 }} spacing={2}>
                                <Grid item>
                                    <TenantDropDown
                                        user={user}
                                        company={company}
                                        handleTenantOptionChange={handleTenantOptionChange}
                                        selectedTenantOption={selectedTenantOption}></TenantDropDown>
                                </Grid>
                            </Grid>
                        )
                    }

                    {!accessDenied && selectedTenantOption && tenantHealthStats.status >= 1 ? (
                        <>
                            <Grid container sx={{ minWidth: 500, width: "100%", paddingX: 2, paddingY: 0 }} spacing={2}>
                                <Grid item>
                                    <PeriodDropDown selectedPeriodOption={selectedPeriodOption}
                                        handlePeriodOptionChange={handlePeriodOptionChange} />
                                </Grid>
                            </Grid>
                            <Grid sx={{ minWidth: 500, width: "100%", padding: 2 }}>
                                Audit data last updated on: {formatAuditJobRunTimeDateServerTimeToGMT(tenantHealthStats.teamsAuditLastSuccessfulRunTime)}
                                <br />
                            </Grid>
                            <Grid sx={{ minWidth: 333, width: "33%", padding: 2 }}>
                                <Paper elevation={3} sx={{ p: 2, border: '1px solid lightgray', height: '100%' }}>
                                    <div style={{ height: '100%' }}>
                                        <AuditGrowth tenantId={focusTenantId} periodToUse={focusPeriodId} reportType="Teams" />
                                    </div>
                                    <br />
                                </Paper>
                            </Grid>
                            { /* Display Teams Activity by Operation. */}
                            <Grid sx={{ minWidth: 333, width: "33%", padding: 2 }}>
                                <Paper elevation={3} sx={{ p: 2, border: '1px solid lightgray', height: '100%', overflowWrap: 'break-word' }}>
                                    <TeamsAllActivity
                                        user={user}
                                        companyId={company.id}
                                        tenantId={focusTenantId}
                                        periodToUse={focusPeriodId}
                                        loadData={false}
                                        filterByColumn={"operation"} />
                                </Paper>
                            </Grid>
                            { /* Display Teams Activity by Operation by Team Name */}
                            <Grid sx={{ minWidth: 333, width: "33%", padding: 2 }}>
                                <Paper elevation={3} sx={{ p: 2, border: '1px solid lightgray', height: '100%' }}>
                                    <TeamsAllActivity
                                        user={user}
                                        companyId={company.id}
                                        tenantId={focusTenantId}
                                        periodToUse={focusPeriodId}
                                        loadData={false}
                                        filterByColumn={"teamname"} />
                                </Paper>
                            </Grid>

                            <Grid sx={{ minWidth: 333, width: "33%", padding: 2 }}>
                                { /* Display total audit record count for Teams. */}
                                <Paper elevation={3} sx={{ p: 2, border: '1px solid lightgray', height: '100%' }}>
                                    <div style={{ height: '100%' }}>
                                        <Typography component="div" variant="h6" sx={{ fontSize: 16, paddingBottom: 1 }}>Total Audit Records</Typography>
                                        <br />
                                        <TableContainer>
                                            <Table style={{ width: '100%', fontSize: 'inherit', fontWeight: 'inherit' }}>
                                                <TableBody>
                                                    <TableRow>
                                                        <TableCell style={{ border: 'none', padding: 2 }}>Teams</TableCell>
                                                        <TableCell style={{ border: 'none', padding: 2 }}><NumberBadge number={tenantHealthStats.totalTeamsAuditRecords} bgcolor='#12D34A' txtcolor='white' /></TableCell>
                                                    </TableRow>
                                                </TableBody>
                                            </Table>
                                        </TableContainer>
                                    </div>
                                    <br />
                                </Paper>
                            </Grid>
                            { /* Display a link to Insights Search specific to Search Teams. */}
                            <Grid sx={{ minWidth: 500, width: "66%", padding: 2 }}>
                                <Paper elevation={3} sx={{ p: 2, border: '1px solid lightgray', height: '100%' }}>
                                    <div style={{ height: '100%' }}>
                                        <Typography component="div" variant="h6" sx={{ fontSize: 16, paddingBottom: 1 }}>Search your Teams Audit Records</Typography>
                                        <br />
                                        <div style={{ textAlign: 'center' }}>
                                            <ManageSearchIcon style={{ fontSize: '3rem' }} />&nbsp;
                                            <Link onClick={() => navigate(`/Reports/InsightsSearch/Teams`)} component="button">Configure and run your Insights Search.</Link>
                                        </div>
                                    </div>
                                    <br />
                                </Paper>
                            </Grid>
                            <Grid sx={{ minWidth: 500, width: "100%", padding: 2 }}>

                                {/* Tab Panel. */}
                                <Box sx={{ width: '100%' }}>
                                    <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                                        <Tabs value={tabValue} onChange={handleTabChange} aria-label="Teams Reports Tab">
                                            <Tab label="Activity Reports" {...a11yProps(0)} />
                                            <Tab label="User Reports" {...a11yProps(1)} />
                                        </Tabs>
                                    </Box>
                                    <TabPanel value={tabValue} index={0}>

                                        <Link onClick={() => navigate(`/Reports/TeamsAllActivityReport/${focusTenantId}/${REPORT_ON_PERIOD_30DAYS}/operation`)} component="button">Teams All Activity (Last 30 days)</Link><br />
                                        <Link onClick={() => navigate(`/Reports/TeamsAllActivityReport/${focusTenantId}/${REPORT_ON_PERIOD_90DAYS}/operation`)} component="button">Teams All Activity (Last 3 months)</Link><br />
                                        <Link onClick={() => navigate(`/Reports/TeamsAllActivityReport/${focusTenantId}/${REPORT_ON_PERIOD_30DAYS}/teamname`)} component="button">Teams All Activity by Team (Last 30 days)</Link><br />
                                        <Link onClick={() => navigate(`/Reports/TeamsAllActivityReport/${focusTenantId}/${REPORT_ON_PERIOD_90DAYS}/teamname`)} component="button">Teams All Activity by Team (Last 3 months)</Link><br />
                                        <Link onClick={() => navigate(`/Reports/TeamsAllActivityByTeamAndChannelReport/${focusTenantId}`)} component="button">All Activity by Team or Channel</Link><br />

                                    </TabPanel>
                                    <TabPanel value={tabValue} index={1}>

                                        <Link onClick={() => navigate(`/Reports/TeamsAllActivityByUserReport/${focusTenantId}`)} component="button">All Activity by User</Link><br />
                                        <Link onClick={() => navigate(`/Reports/TeamsAllActivityByIPAddressReport/${focusTenantId}`)} component="button">All Activity by IP Address</Link><br />
                                    </TabPanel>
                                </Box>

                            </Grid>

                        </>
                    ) :
                        (
                            <>
                                <Grid sx={{ minWidth: 500, width: "100%", padding: 2 }}>
                                    <ProgressBar message="Loading ..." loading={true} />
                                </Grid>
                            </>
                        )
                    }
                    <Grid sx={{ minWidth: 500, width: "100%", padding: 2 }}>
                        <br />
                        Need a custom or new report?  Provide us with your <Link onClick={() => navigate(`/Support/SupportHome`)} component="button">Product Feedback</Link>.
                        <br />* Unless otherwise specified as UTC, graphs on page are using your browser's local timezone.
                    </Grid>
                </Grid>
            </div>
        </>)
}
export default ReportsTeams