// React Components
import * as React from 'react';
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import PropTypes from 'prop-types';

// Material UI Components
import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';

// Audit Vault Components
import AccessDenied from '../components/common/AccessDenied';
import CompanyNag from "../components/common/CompanyNag";
import TrialPeriodNag from '../components/common/TrialPeriodNag';
import ServiceIsDown from '../components/common/ServiceIsDown';
import ProgressBar from '../components/common/ProgressBar';
import AddTenantButton from '../components/AddTenantButton';
import DisplayBreadCrumb from "../components/common/DisplayBreadCrumb";
import DisplaySectionTitleAndDescription from "../components/common/DisplaySectionTitleAndDescription";
import JobsList from '../components/jobs/JobsList';
import TenantDropDown from "../components/reports/TenantDropDown";
import TenantRoleList from '../components/role/TenantRoleList';
import TenantDetails from '../components/tenant/TenantDetails';
import TenantHistory from '../components/tenant/TenantHistory';
import TenantSetupRequired from '../components/tenant/TenantSetupRequired';

// Audit Vault Utilities
import { getCompanyByCompanyId } from '../utilities/common-company';
import { getTenantListFromUserPerms, verifyUserHasTenantRolePermissions } from '../utilities/common-user-utils';

// Audit Vault Constants
const theSectionTitle = "Your Tenant Dashboard";
const theSectionDesc = "Review and configure your Tenant settings here.  Review and edit your Audit Vault for M365's Tenant User Role security access assignments, and view your Tenant's Audit Vault Jobs.";
const isAdminPage = false;
import { ROLE_CODE_TENANTADMINISTRATOR } from '../constants/constants-roles';
import { LINK_TO_AUDIT_HISTORY_MENU_DEPLOYMENT_GUIDE, LINK_TO_OUR_MICROSOFT_SHAREPOINT_APP_STORE_PAGE, LINK_TO_OUR_WEBSITE_HELP_CENTER } from '../constants/constants-links';

function MyTenant(props) {

    const { user, openCompanyNag, setOpenCompanyNag } = props;
    const queryParams = new URLSearchParams(window.location.search);
    const navigate = useNavigate();

    // Component Constants
    const [company, setCompany] = useState("");
    const [loading, setLoading] = useState(true);
    const [accessDenied, setAccessDenied] = useState(false);
    const [serviceIsDownError, setServiceIsDownError] = useState(false);

    const [value, setValue] = useState(0);  // Controls which tab is active.
    const [selectedTenant, setSelectedTenant] = useState(0);

    const [selectedTenantOption, setSelectedTenantOption] = useState('');
    const [selectedTenantChanged, setSelectedTenantChanged] = useState(false);
    const [selectedRole, setSelectedRole] = useState(1);

    const theBreadcrumbPath = [
        { name: 'Home', link: '/' },
        { name: 'Tenant', link: '/Tenant/MyTenant?tab=0' },
    ];

    // Component Functions

    function TabPanel(props) {
        const { children, value, index, ...other } = props;

        return (
            <div
                role="tabpanel"
                hidden={value !== index}
                id={`simple-tabpanel-${index}`}
                aria-labelledby={`simple-tab-${index}`}
                {...other}
            >
                {value === index && (
                    <Box sx={{ p: 3 }}>
                        <Typography>{children}</Typography>
                    </Box>
                )}
            </div>
        );
    }

    TabPanel.propTypes = {
        children: PropTypes.node,
        index: PropTypes.number.isRequired,
        value: PropTypes.number.isRequired,
    };

    function a11yProps(index) {
        return {
            id: `simple-tab-${index}`,
            'aria-controls': `simple-tabpanel-${index}`,
        };
    }

    async function fetchData() {

        if (loading && user) {
            try {
                const response = await getCompanyByCompanyId(user.companyId, setServiceIsDownError);
                if (response) {
                    setCompany(response);
                    if (response != null) {

                        // Get a list of tenant that the user has perms to
                        var tenantList = getTenantListFromUserPerms(user, response);
                        if (tenantList.length > 0) {
                            let tenantId = tenantList[0].id;
                            if (queryParams.get("tenantId") && queryParams.get("tenantId").length > 0) {
                                // pull the tenant Id from the query string and set the tenant drop down to the tenant to display current membership
                                tenantId = Number(queryParams.get("tenantId"));
                                for (var x = 0; x < tenantList.length; x++) {
                                    if (tenantList[x].id == tenantId) {
                                        setSelectedTenantOption(tenantList[x]);
                                    }
                                }
                            }
                            else {
                                setSelectedTenantOption(tenantList[0]);
                            }
                            setSelectedTenant(tenantId);

                            // check if the tuser can Tenant Admin activities on this tenant
                            if (verifyUserHasTenantRolePermissions(user, tenantId, ROLE_CODE_TENANTADMINISTRATOR)) {

                                if (queryParams.get("roleId") && queryParams.get("roleId").length > 0) {
                                    let roleNum = Number(queryParams.get("roleId"));
                                    setSelectedRole(roleNum);
                                    // open the role tab
                                    setValue(1);
                                }
                            }
                            else {
                                setAccessDenied(true);
                            }
                        }
                    }
                }
            }
            catch (e) {
                setServiceIsDownError(true);
                console.log(e)
            }
            finally {
                setLoading(false);
            }
        }
    }

    useEffect(() => {
        fetchData();
        checkTabToDisplay();
    }, [window.location.search]);


    function checkTabToDisplay() {
        var tabVal = queryParams.get("tab");
        if (tabVal && tabVal.length > 0) {
            setValue(parseInt(tabVal, 10));  // Cast and set the tab number value to display.                            
        }
    }

    const handleChange = (event, newValue) => {
        setValue(newValue);
    };

    const handleTenantOptionChange = (event) => {
        setSelectedTenantOption(event.target.value);
        setSelectedTenant(event.target.value.id);
    };

    const TenantSetupRequiredMsg = () => {
        return (
            <TenantSetupRequired />
        );
    };

    // Component UI
    if (loading) {
        return <ProgressBar message="Loading ..." loading={loading} />
    }

    if (serviceIsDownError) {
        return (<><ServiceIsDown></ServiceIsDown></>);
    }

    if (accessDenied) {
        return (<AccessDenied></AccessDenied>);
    }

    if (company && company.tenantList.length == 0) {
        return (<>
            <DisplayBreadCrumb paths={theBreadcrumbPath} />
            <DisplaySectionTitleAndDescription sectionTitle={theSectionTitle} sectionDescription={theSectionDesc} isAdminPage={isAdminPage} />
            <CompanyNag company={company} openCompanyNag={openCompanyNag} setOpenCompanyNag={setOpenCompanyNag}></CompanyNag>
            {company.setupStageComplete >= 6 && <Button size="small" variant="contained" onClick={(event) => navigate(`/tenant-create`)}>Add Tenant</Button>}
        </>)
    }

    if (!loading && company) {

        return (
            <>
                <TrialPeriodNag company={company}></TrialPeriodNag>
                <DisplayBreadCrumb paths={theBreadcrumbPath} />
                <DisplaySectionTitleAndDescription sectionTitle={theSectionTitle} sectionDescription={theSectionDesc} isAdminPage={isAdminPage} />
                <CompanyNag company={company} openCompanyNag={openCompanyNag} setOpenCompanyNag={setOpenCompanyNag} />
                <TenantDropDown
                    user={user}
                    company={company}
                    handleTenantOptionChange={handleTenantOptionChange}
                    selectedTenantOption={selectedTenantOption}></TenantDropDown>

                <Box sx={{ width: '100%', minWidth: 1000 }}>
                    <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                        <Tabs value={value} onChange={handleChange} aria-label="basic tabs example">
                            <Tab label="Tenant Summary" {...a11yProps(0)} />
                            <Tab label="Roles" {...a11yProps(1)} />
                            <Tab label="SharePoint App" {...a11yProps(2)} />
                            <Tab label="Jobs" {...a11yProps(3)} />
                            <Tab label="Tenant History" {...a11yProps(4)} />
                        </Tabs>
                    </Box>
                    <TabPanel value={value} index={0}>
                        <Typography component="span">
                            {company &&
                                <TenantDetails user={user} tenantId={selectedTenant}></TenantDetails>
                            }
                        </Typography>
                        <Typography><br></br></Typography>
                        <AddTenantButton />
                    </TabPanel>
                    <TabPanel value={value} index={1}>
                        <Typography component="span">
                            {company.setupStageComplete > 3 && selectedTenantOption.firstAuditImportStage > 0 ?
                                <>
                                    <Typography>Below are the default security access role assignments for the selected Tenant.<br />Give users only the access they need by assigning the least-permissive role.</Typography>
                                    <Typography><br></br></Typography>
                                    <TenantRoleList user={user}
                                        tenantId={selectedTenant}
                                        roleNum={selectedRole}
                                        sourcePage="MyTenant"
                                        setSelectedTenantChanged={setSelectedTenantChanged}
                                        selectedTenantChanged={selectedTenantChanged}
                                    ></TenantRoleList>
                                </>
                                :
                                TenantSetupRequiredMsg()
                            }
                        </Typography>
                    </TabPanel>
                    <TabPanel value={value} index={2}>
                        {company.setupStageComplete > 3 &&
                            <>
                                <Typography component="div">
                                    <p><b>What is it:</b><br></br>
                                        The Audit Vault for M365 - Audit History for SharePoint menu is an optional free app that can be added to your SharePoint tenant. The app allows you to enable an "Audit history" menu item and a command bar button directly in SharePoint.&nbsp; When used, the menu opens the Audit Vault for M365 "View SharePoint Item's Audit History" Report to display all the audit logs for the selected SharePoint Item.</p>
                                    <p><b>Why you need it:</b><br></br>
                                        Easily view Audit Log History directly from an Item in SharePoint Online:</p>
                                    <ul>
                                        <li>Expose and surface Item Audit History in SharePoint Online to empower your end users.</li>
                                        <li>Identify permissions issues.</li>
                                        <li>Track user activity on a particular document, folder or list item.</li>
                                    </ul>
                                    <p><b>How to deploy it:</b><br></br>
                                        Have your Microsoft Global or SharePoint Admin add the "Audit History for SharePoint" App to your Tenant from the Microsoft AppSource Store:</p>
                                    <p>
                                        <ul>
                                            <li><a href={LINK_TO_OUR_MICROSOFT_SHAREPOINT_APP_STORE_PAGE} target="_blank" rel="noopener noreferrer">Microsoft AppSource: Audit History for SharePoint</a></li>
                                        </ul>
                                    </p>
                                    <p>
                                        <Button type="button" href={`${LINK_TO_AUDIT_HISTORY_MENU_DEPLOYMENT_GUIDE}`} target="_blank">Learn More</Button>
                                    </p>
                                </Typography>
                                <Card variant="outlined">
                                    <CardContent>
                                        <Typography component="div" variant="h5" sx={{ fontSize: 19, fontWeight: '800', paddingBottom: 0 }}>Current Audit History for SharePoint App Access:</Typography>                                        
                                        <div style={{ color: 'green' }}>
                                            {selectedTenantOption && selectedTenantOption.useSaaPermsToAccessSpAudit === true ?
                                                <>
                                                    <Typography component="div" variant="h6" sx={{ fontSize: 16, paddingBottom: 0 }}>Uses Audit Vault for M365 Permissions</Typography>
                                                    Requires users to have at least "Tenant Viewer" role access in Audit Vault for M365.
                                                </>
                                                :
                                                <>
                                                    <Typography component="div" variant="h6" sx={{ fontSize: 16, paddingBottom: 0 }}>Uses SharePoint Permissions</Typography>
                                                    Requires users to have at least "View/Read" access to the Item in SharePoint.
                                                </>

                                            }
                                        </div>
                                    </CardContent>
                                    <CardActions>
                                        <Button variant="contained" type="button" onClick={() => navigate(`/tenant-update/${selectedTenant}`)}>Edit SharePoint App Permissions</Button>
                                    </CardActions>
                                </Card>

                            </>
                        }
                        {company.setupStageComplete <= 3 &&
                            TenantSetupRequiredMsg()
                        }
                    </TabPanel>

                    <TabPanel value={value} index={3}>

                        {company && company.setupStageComplete > 3 && selectedTenant && selectedTenantOption.firstAuditImportStage > 0 ?
                            <> <Typography component="div">Listed below are the Audit Jobs assigned to this tenant. Expand the section to view the job history over the past month.</Typography>
                                <br />
                                <JobsList selectedTenant={selectedTenant} />
                            </>
                            :
                            TenantSetupRequiredMsg()
                        }
                    </TabPanel>

                    <TabPanel value={value} index={4}>
                        {company && company.setupStageComplete > 3 && selectedTenant && selectedTenantOption.firstAuditImportStage > 0 ?
                            <TenantHistory user={user} company={company} tenantId={selectedTenant}></TenantHistory>
                            :
                            TenantSetupRequiredMsg()
                        }
                    </TabPanel>
                </Box>
            </>
        );
    }
}
export default MyTenant;