// React Components
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useMsal } from "@azure/msal-react";

// Material UI Components
import Grid from '@mui/material/Grid';
import Link from '@mui/material/Link';
import Paper from '@mui/material/Paper';
import TableContainer from '@mui/material/TableContainer';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableRow from '@mui/material/TableRow';
import Typography from '@mui/material/Typography';
import ManageSearchIcon from '@mui/icons-material/ManageSearch';

// Audit Vault Components
import CompanyNag from "../components/common/CompanyNag";
import TrialPeriodNag from "../components/common/TrialPeriodNag";
import ServiceIsDown from "../components/common/ServiceIsDown";
import TenantStats from "../components/TenantStats";
import TenantDBStats from "../components/TenantDBStats";
import ProgressBar from "../components/common/ProgressBar";
import DisplaySectionTitleAndDescription from '../components/common/DisplaySectionTitleAndDescription';
import DisplayBreadCrumb from "../components/common/DisplayBreadCrumb";
import MessageListPreview from "../components/MessageListPreview";
import HelpCenter from "../pages/HelpCenter";
import ImageSharePoint from '../imgs/iconSharePoint.svg';
import ImageExchange from '../imgs/iconExchange.svg';
import ImageEntraID from '../imgs/iconEntraID.svg';
import BillingInfo from "../components/billing/BillingInfo";

// Audit Vault Common
import { getCompanyByCompanyId, getCompanyByAzureTenantId } from "../utilities/common-company";
import { getSubscriptionInfo, getCompanyProductsInfo } from "../utilities/common-getBillingInfo";

let theSectionTitle = "Welcome to the Audit Vault for M365 App";
// Add playful random description to the Home Page.  
const messages = [
  "We love to manage your organization's vital M365 audit information!  Below you will find functions to help you access valuable insights from your audit data.",
  "Good day!  Thanks for choosing ECM Insights to manage your M365 audit management requirements.  Below you will find functions to help you access valuable insights from your audit data.",
  "Knowing is half the battle - Audit Vault for M365 preserves your valuable audit information.  Below you will find functions to help you access valuable insights from your audit data.",
  "Thanks for choosing Audit Vault for M365 to simply and powerfully preserve all of your M365 audit records. Below you will find functions to help you access valuable insights from your audit data.",
  "Unlock Enhanced Compliance Confidence in Microsoft M365 with Audit Vault for M365.",
  "Audit Vault for M365 empowers organizations to thrive in highly regulated environments by preserving, shielding, and intelligently surfacing your audit history information where and when you need it.",
  "A simply powerful and cost effective solution to retain and surface your organization's SharePoint Audit records for as long as you require.",
  "Audit Vault for M365 ensures your use of Microsoft 365 is GxP and 21 CFR Part 11 compliant, preventing costly non-compliance issues. ",
];
const randomIndex = Math.floor(Math.random() * messages.length);

function MyHome(props) {

  const { user, serviceIsDownError, setServiceIsDownError, openCompanyNag, setOpenCompanyNag } = props;

  const { accounts } = useMsal();

  // Component Constants
  const [company, setCompany] = useState("");
  const [companyProducts, setCompanyProducts] = useState("");
  const [subscription, setSubscription] = useState("");
  const [sectionDesc, setSectionDesc] = useState("");
  const [loading, setLoading] = useState(true);

  const navigate = useNavigate();

  // Section titles.
  const [isAdminPage] = useState(false);
  const theBreadcrumbPath = [
    { name: 'Home', link: '/' }
  ];


  useEffect(() => {
    async function fetchData() {
      try {
        if (user) {
          if (user.isSystemAdmin && user.companyId == 0) {
            // Just a Audit Vault System Admin with no other company access
            navigate(`/Admin/AdminHome`)
          }
          else if (user.companyId == 0) {
            // try getting the company by azure tenant Id
            var checkResponse = await getCompanyByAzureTenantId(accounts[0].tenantId);
            if (checkResponse) {
              setCompany(checkResponse);
            }
          }
          else {
            // Load the company.
            const response = await getCompanyByCompanyId(user.companyId, setServiceIsDownError);
            if (response) {
              setCompany(response);
              if (response.setupStageComplete > 0) {
                // Load the products and subscription info for the billing component.
                var productsReponse = await getCompanyProductsInfo(response.id, setCompanyProducts);
                if (productsReponse) {
                  for (var i = 0; i < productsReponse.products.length; i++) {
                    var subResponse = await getSubscriptionInfo(productsReponse.products[i].stripeSubscriptionId, setSubscription);
                    if (subResponse) {
                      setSectionDesc(messages[randomIndex])
                    }

                  }
                }
              }
            }
          }
        }
      }
      catch (e) {
        console.log(e)
      }
      finally {
        setLoading(false);
      }
    }
    fetchData();
  }, [user]);


  // Component UI
  if (serviceIsDownError) {
    return (
      <>
        <ServiceIsDown></ServiceIsDown>
      </>);
  }

  // Component UI
  if (loading) {
    return (<ProgressBar message="Loading ..." loading={loading} />);
  }

  if (!loading && !company) {
    // Occurs when logging in via Sign In button
    navigate(`/MyCompanySetupCreate`)
  }

  if (!loading && company && company.setupStageComplete == 0) {
    navigate(`/MyCompanySetupUpdate`)
  }

  if (!loading && company && company.setupStageComplete > 0)
    return (
      <>
        <TrialPeriodNag company={company}></TrialPeriodNag>
        <DisplayBreadCrumb paths={theBreadcrumbPath} />
        <DisplaySectionTitleAndDescription sectionTitle={theSectionTitle + " - " + company.companyName} sectionDescription={sectionDesc} isAdminPage={isAdminPage} />
        <CompanyNag company={company} openCompanyNag={openCompanyNag} setOpenCompanyNag={setOpenCompanyNag} />
        <Grid container spacing={2}>
          {
            ((user.permissions.length > 0 && user.permissions[0].roleId <= 4) || (user.isSystemAdmin) || (user.isCompanyAdmin)) &&

            company.tenantList.map((tenant) =>
              <Grid sx={{ minWidth: 500, width: "50%", padding: 2 }}>
                <Paper elevation={3} sx={{ p: 2, border: '1px solid lightgray', height: '100%' }}>
                  <Typography component="div" variant="h6" sx={{ fontSize: 16, paddingBottom: 1 }}>Tenant Summary</Typography>
                  <TenantStats tenant={tenant} user={user} reportPageId={10} />
                </Paper>
              </Grid>
            )
          }

          {
            ((user.permissions.length > 0 && user.permissions[0].roleId <= 4) || (user.isSystemAdmin) || (user.isCompanyAdmin)) &&
            company.tenantList.map((tenant) =>
              <Grid sx={{ minWidth: 500, width: "50%", padding: 2 }}>
                <Paper elevation={3} sx={{ p: 2, border: '1px solid lightgray', height: '100%' }}>
                  <Typography component="div" variant="h6" sx={{ fontSize: 16, paddingBottom: 1 }}>Tenant Audit Storage Summary</Typography>
                  <TenantDBStats user={user} tenant={tenant} viewFullSize={true} showLinkInFullSize={true} showTenantUserCount={false} />
                </Paper>
              </Grid>
            )
          }

          <Grid sx={{ minWidth: 500, width: "50%", wordSpacingidth: "50%", padding: 2 }}>
            <Paper elevation={3} sx={{ p: 2, border: '1px solid lightgray', height: '100%' }}>
              <Typography component="div" variant="h6" sx={{ fontSize: 16, paddingBottom: 1 }}>Message Center</Typography>
              <MessageListPreview theCompanyId={company.id}></MessageListPreview><br />
            </Paper>
          </Grid>
          <Grid sx={{ minWidth: 500, width: "50%", padding: 2 }}>
            <Paper elevation={3} sx={{ p: 2, border: '1px solid lightgray', height: '100%' }}>
              <HelpCenter previewOnly={true} />
            </Paper>
          </Grid>

          {
            // Only display report links if you are a Report User.
            ((user.permissions.length > 0 && user.permissions[0].roleId <= 3) || (user.isSystemAdmin) || (user.isCompanyAdmin)) &&
            <>
              <Grid sx={{ minWidth: 500, width: "50%", wordSpacingidth: "50%", padding: 2 }}>
                <Paper elevation={3} sx={{ p: 2, border: '1px solid lightgray', height: '100%' }}>
                  <div style={{ height: '100%' }}>
                    <Typography component="div" variant="h6" sx={{ fontSize: 16, paddingBottom: 1 }}>Search your Audit Records</Typography>
                    <br />
                    <div style={{ textAlign: 'center' }}>
                      <ManageSearchIcon style={{ fontSize: '3rem' }} />&nbsp;
                      <Link onClick={() => navigate(`/Reports/InsightsSearch/All`)} component="button">Configure and run an Insights Search.</Link>
                    </div>
                  </div>
                  <br />
                </Paper>
              </Grid>
              <Grid sx={{ minWidth: 500, width: "50%", padding: 2 }}>
                <Paper elevation={3} sx={{ p: 2, border: '1px solid lightgray', height: '100%' }}>
                  <div style={{ height: '100%' }}>
                    <Typography component="div" variant="h6" sx={{ fontSize: 16, paddingBottom: 1 }}>Reports by Workload</Typography>
                    <br />
                    <TableContainer>
                      <Table style={{ width: '100%', fontSize: 'inherit', fontWeight: 'inherit' }}>
                        <TableBody>
                          <TableRow>
                            <TableCell style={{ border: 'none', padding: 2, textAlign: 'center' }}><Link onClick={() => navigate(`/Reports/SharePoint`)} component="button"><img src={ImageSharePoint} alt="SharePoint Reports" /></Link></TableCell>
                            <TableCell style={{ border: 'none', padding: 2, textAlign: 'center' }}><Link onClick={() => navigate(`/Reports/Exchange`)} component="button"><img src={ImageExchange} alt="Exchange Reports" /></Link></TableCell>
                            <TableCell style={{ border: 'none', padding: 2, textAlign: 'center' }}><Link onClick={() => navigate(`/Reports/EntraID`)} component="button"><img src={ImageEntraID} alt="Entra ID" /></Link></TableCell>
                            <TableCell style={{ border: 'none', padding: 2, textAlign: 'center' }}>
                              <Link onClick={() => navigate(`/Reports/Teams`)} component="button">
                                <svg viewBox="-0.12979372698077785 0 32.42343730730004 32" xmlns="http://www.w3.org/2000/svg" width="50px" height="50px"><circle cx="17" cy="6" fill="#7b83eb" r="4.667" /><path d="M16.667 7H12.44l.021.093.002.008.022.086A4.671 4.671 0 0 0 18 10.559V8.333A1.337 1.337 0 0 0 16.667 7z" opacity=".1" /><path d="M15.667 8h-2.884A4.667 4.667 0 0 0 17 10.667V9.333A1.337 1.337 0 0 0 15.667 8z" opacity=".2" /><circle cx="27.5" cy="7.5" fill="#5059c9" r="3.5" /><path d="M30.5 12h-7.861a.64.64 0 0 0-.64.64v8.11a5.121 5.121 0 0 0 3.967 5.084A5.006 5.006 0 0 0 32 20.938V13.5a1.5 1.5 0 0 0-1.5-1.5z" fill="#5059c9" /><path d="M25 13.5V23a7.995 7.995 0 0 1-14.92 4 7.173 7.173 0 0 1-.5-1 8.367 8.367 0 0 1-.33-1A8.24 8.24 0 0 1 9 23v-9.5a1.498 1.498 0 0 1 1.5-1.5h13a1.498 1.498 0 0 1 1.5 1.5z" fill="#7b83eb" /><path d="M15.667 8h-2.884A4.667 4.667 0 0 0 17 10.667V9.333A1.337 1.337 0 0 0 15.667 8z" opacity=".2" /><path d="M18 12v12.67a1.32 1.32 0 0 1-1.04 1.29.966.966 0 0 1-.29.04H9.58a8.367 8.367 0 0 1-.33-1A8.24 8.24 0 0 1 9 23v-9.5a1.498 1.498 0 0 1 1.5-1.5z" opacity=".1" /><path d="M17 12v13.67a.967.967 0 0 1-.04.29A1.32 1.32 0 0 1 15.67 27h-5.59a7.173 7.173 0 0 1-.5-1 8.367 8.367 0 0 1-.33-1A8.24 8.24 0 0 1 9 23v-9.5a1.498 1.498 0 0 1 1.5-1.5z" opacity=".2" /><path d="M17 12v11.67A1.336 1.336 0 0 1 15.67 25H9.25A8.24 8.24 0 0 1 9 23v-9.5a1.498 1.498 0 0 1 1.5-1.5z" opacity=".2" /><path d="M10.5 12A1.498 1.498 0 0 0 9 13.5V23a8.24 8.24 0 0 0 .25 2h5.42A1.336 1.336 0 0 0 16 23.67V12z" opacity=".2" /><path d="M1.333 8h13.334A1.333 1.333 0 0 1 16 9.333v13.334A1.333 1.333 0 0 1 14.667 24H1.333A1.333 1.333 0 0 1 0 22.667V9.333A1.333 1.333 0 0 1 1.333 8z" fill="#4b53bc" /><path d="M11.98 12.975H8.99v8.02H7.028v-8.02H4.02v-1.97h7.96z" fill="#fff" /><path d="M0 0h32v32H0z" fill="none" /></svg>
                              </Link>
                            </TableCell>
                          </TableRow>
                          <TableRow>
                            <TableCell style={{ border: 'none', padding: 2, textAlign: 'center' }}><Link onClick={() => navigate(`/Reports/SharePoint`)} component="button">SharePoint</Link></TableCell>
                            <TableCell style={{ border: 'none', padding: 2, textAlign: 'center' }}><Link onClick={() => navigate(`/Reports/Exchange`)} component="button">Exchange</Link></TableCell>
                            <TableCell style={{ border: 'none', padding: 2, textAlign: 'center' }}><Link onClick={() => navigate(`/Reports/EntraID`)} component="button">Entra ID</Link></TableCell>
                            <TableCell style={{ border: 'none', padding: 2, textAlign: 'center' }}><Link onClick={() => navigate(`/Reports/Teams`)} component="button">Teams</Link></TableCell>
                          </TableRow>
                        </TableBody>
                      </Table>
                    </TableContainer>
                  </div>
                  <br />
                </Paper>
              </Grid>
            </>
          }


          {
            // Only display billling information if you are a Company Administrator.
            ((user.permissions.length > 0 && user.permissions[0].roleId <= 1) || (user.isSystemAdmin) || (user.isCompanyAdmin)) && company.stripeCustomerId && subscription &&
            <Grid sx={{ minWidth: 500, width: "50%", padding: 2 }}>
              <Paper key="billing" elevation={3} sx={{ p: 2, border: '1px solid lightgray', height: '100%' }}>
                <Typography component="div" variant="h6" sx={{ fontSize: 16, paddingBottom: 1 }}>Billing and Payments</Typography>
                <BillingInfo subscription={subscription} companyId={company.id} />
                <br />
              </Paper>
            </Grid>
          }
        </Grid>
      </>

    );
}
export default MyHome;