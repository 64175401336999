// React Components
import * as React from 'react';
import { useEffect, useState } from "react";
import axios from "axios";

// Material UI Components
import Typography from '@mui/material/Typography';
import { Line } from 'react-chartjs-2';
import { Chart as ChartJS, Title, Legend, Tooltip, TimeScale, LinearScale, PointElement, LineElement, CategoryScale} from 'chart.js';
import ChartDataLabels from 'chartjs-plugin-datalabels'; 
import 'chartjs-adapter-date-fns';
import { enUS } from 'date-fns/locale'; 

// Audit Vault Components
import ServiceIsDown from '../../components/common/ServiceIsDown';
import ProgressBar from '../../components/common/ProgressBar';

// Audit Vault Utilities
import {reportTitleAddPeriodToUseText} from '../../utilities/common-report-utils';
import { encryptKey } from '../../utilities/common-encrypt-util';
import { formatDate } from '../../utilities/common-date-utils';

import { REPORT_ON_PERIOD_1WEEK, REPORT_ON_PERIOD_30DAYS, REPORT_ON_PERIOD_90DAYS, REPORT_ON_PERIOD_180DAYS, REPORT_ON_PERIOD_1YEAR } from "../../constants/constants-reportperiods";


// Note, below is the tree-shakable way to decrease the bundle size.
ChartJS.register(Title, Legend, Tooltip, TimeScale, LinearScale, PointElement, LineElement, CategoryScale);
ChartJS.register(ChartDataLabels);

export default function AuditGrowth(props) {

    // Component Constants
    const { tenantId, periodToUse, reportType } = props;        
    
    const [loading, setLoading] = useState(true);    
    const [serviceIsDownError, setServiceIsDownError] = useState(false);    
    const WEB_API_URL = process.env.REACT_APP_WEB_API_URL;

    const [reportResults, setReportResults] = useState([]);

    var theGraphTitle = "Audit Growth ";
   
    // Component Functions
    useEffect(() => {

        async function fetchData() {

            try 
            {   
                var periodLength = 30;
                switch (periodToUse)
                {
                    case REPORT_ON_PERIOD_1WEEK:
                        periodLength = 7;
                        break;

                    case REPORT_ON_PERIOD_30DAYS:
                        periodLength = 30;
                        break;

                    case REPORT_ON_PERIOD_90DAYS:
                        periodLength = 90;
                        break;

                    case REPORT_ON_PERIOD_180DAYS:
                        periodLength = 180;
                        break;

                    case REPORT_ON_PERIOD_1YEAR:
                        periodLength = 365;
                        break;
                }

                if (reportType == 'All')
                {
                    var payload = {
                        tenantId: tenantId,
                        period: periodLength                           
                    }; 
                    var rsaKey = await encryptKey(JSON.stringify(payload));
                    var response = await axios.get(`${WEB_API_URL}Reports/GetAllAuditGrowthReport`, {
                        params: {
                            key: `${rsaKey}`
                        }
                    });

                    if (response)
                    {
                        //console.log(response.data);
                        setReportResults(response.data);
                    }
                }
                else
                {
                    var payload = {
                        tenantId: tenantId,
                        period: periodLength,
                        reportType: reportType                            
                    }; 
                    var rsaKey = await encryptKey(JSON.stringify(payload));
                    var response = await axios.get(`${WEB_API_URL}Reports/GetAuditGrowthReport`, {
                        params: {
                            key: `${rsaKey}`
                        }
                    });

                    if (response)
                    {
                        //console.log(response.data);
                        setReportResults(response.data);
                    }
                }
            }   
            catch(e) 
            {
                console.log("ERROR: AuditGrowth.fetchData");
                console.log(e);
                setServiceIsDownError(true);
            }
            finally
            {
                setLoading(false);
            }                              
        }
        fetchData();
    }, [periodToUse]);
        
    
    // Component UI    
    // If we are still loading data, display that in the UI...
    if (loading) {
        //console.log("Loading Data ....")
        return (<ProgressBar message="Loading ..." loading={loading} />);
    }

    // Add check to see if WebAPI service is running or not.
    if (serviceIsDownError) {
        return (<><ServiceIsDown></ServiceIsDown></>);
    }

    function GetBorderColour(type)
    {
        switch (type)
        {
            case 'SharePoint':
                return 'rgba(255, 99, 132, 1)';

            case 'Exchange':
                return 'rgba(54, 162, 235, 1)';

            case 'Entra ID':
                return 'rgba(255, 205, 86, 1)';

            case 'Teams':
                return 'rgb(18, 211, 74, 1)';
                
        }
    }
   
    // Prepare data for the chart
    function GetDataPoints(obj)
    {
        let cumulativeCounter = 0;
        const dataPoints = obj.datasets.map((dataPoint, index) => {       
            cumulativeCounter += dataPoint.auditHistoryCount;        
            return {
                label: "+" + dataPoint.auditHistoryCount,
                x: new Date(dataPoint.endDate).getTime(),
                y: cumulativeCounter,
            };
        });
        return dataPoints;
    }

    var dataPoints;
    var data;
    
    if (reportType == 'All')
    {
        var ds = [];

        for (var i = 0; i < reportResults.length; i++) 
        {
            ds.push({
                label: reportResults[i].auditReportType,
                data: GetDataPoints(reportResults[i]),
                borderColor: GetBorderColour(reportResults[i].auditReportType),
                fill: false,
            },)
           
        }
        data = { datasets: ds};
    }

    if (reportType != 'All')
    {
        dataPoints= GetDataPoints(reportResults);

        data = {        
            datasets: [
                {
                    label: reportType,
                    data: dataPoints,
                    borderColor: GetBorderColour(reportType),
                    fill: false,
                },
            ],
        };
    }

   
    const options = {
        scales: {
        x: {
            type: 'time',
            adapters: { 
                date: {
                locale: enUS, 
                },
            },                         
            time: {
                unit: 'day', // Set the desired unit (day, week, month, etc.)
            },
            title: {  // The x-axis title.
                display: true,  
                text: 'Date',
            },
        },
        y: {
            beginAtZero: true,
            title: {  // The y-axis title.
                display: true,
                text: 'Audit Record Count',
            },
        },
        },
        plugins: {
            legend: {
            display: true, 
            position: 'right', // Adjust the legend position ('top', 'bottom', 'left', 'right')
            labels: {
                boxHeight: 10, // Adjust the height of each legend item
            },
            },
            tooltip: {
                callbacks: {
                    title: function(tooltipItems, data) {
                        //Return value for title
                        try{
                            var dataSetIndex = tooltipItems[0].dataIndex + 1;
                            var ds = reportResults.datasets[dataSetIndex];
                            if (dataSetIndex > 0)
                            {
                                return (formatDate(ds.startDate) + " to " + formatDate(ds.endDate));
                            }
                        }
                        catch (e)
                        {
                            //console.log(e)
                        }
                    },
                },
            },
        },        
    };
    
    // Add the graph title.
    theGraphTitle = reportTitleAddPeriodToUseText(theGraphTitle, periodToUse);

    //console.log(data)
    return (
        /*   // NOTE: We can use the code below to look at the results, save results, and export results.
        <ul>            
            {reportResults.map((result, index) => (
            <li key={index}>
                <ul>StartDate: {reportInputData[index].periodStartDate}, count: {reportResults[index].length}</ul>
                <ul>                
                {result.map((item, itemIndex) => (
                    <li key={itemIndex}>
                    {item.id}, {item.creationTime}, {getTenantsRecordedTime(item.creationTime)}  
                    </li>
                ))}
                </ul>
            </li>
            ))}
        </ul>    
        */
        <>
        <Typography component="div" variant="h6" sx={{ fontSize: 16, paddingBottom: 1 }}>{theGraphTitle}</Typography>
        <Line data={data} options={options} />
        </>
    );
}
