// React Components
import * as React from 'react';
import { useNavigate, useParams } from "react-router-dom";
import { useMsal } from '@azure/msal-react';
import { useEffect, useState, setState } from "react";
import axios from "axios";

// Material UI Components
import { Grid, Paper } from '@mui/material';
import TableContainer from '@mui/material/TableContainer';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableRow from '@mui/material/TableRow';
import Button from '@mui/material/Button';
import Info from '@mui/icons-material/Info';
import Typography from '@mui/material/Typography';

// Audit Vault Components
import ServiceIsDown from "../components/common/ServiceIsDown";
import ProgressBar from "../components/common/ProgressBar";

// Audit Vault Constants
import { LINK_TO_OUR_WEBSITE_HELP_CENTER } from '../constants/constants-links';

// Audit Vault Utilities


const WEB_API_URL = process.env.REACT_APP_WEB_API_URL;

function HelpCenter(props) {

    // Component Constants    
    const { user, previewOnly } = props;

    // State variables to store form inputs    
    const navigate = useNavigate();
    const { instance, accounts } = useMsal();
    const name = accounts[0] && accounts[0].username;

    const [loading, setLoading] = useState(false);
    const [serviceIsDownError, setServiceIsDownError] = useState(false);
    // For snackbar notification UI element.  Notification.
    const [openNotification, setOpenNotification] = useState(false);  // Add React hook to add state to a functional component.  It returns the current state, and a function to update it.
    const [snackbarMessage, setSnackbarMessage] = useState('');

    // Section titles.
    const [isAdminPage] = useState(false);
    const theSectionTitle = "Visit Help Center";
    const theSectionDesc = "Discover how to make the most of Audit Vault for M365 with training, docs, and videos covering product capabilities and how-to articles. " +
        "Learn about the suite of capabilities that Audit Vault for M365 provides and ensure that your business unlocks the benefits from preserving all of your M365 audit records and help you to unlock and access valuable insights from your audit data.";

    // Component Functions
    useEffect(() => {
    }, []);


    // Component UI
    if (loading) {
        return (<ProgressBar message="Loading ..." loading={loading} />);
    }

    if (serviceIsDownError) {
        return (<><ServiceIsDown></ServiceIsDown></>);
    }

    if (previewOnly != null && previewOnly == true) {
        // Preview mode.
        return (
            <>
                <TableContainer><Table>
                    <TableBody>
                        <TableRow>
                            <TableCell style={{ maxWidth: 20, border: 'none', padding: 2, whiteSpace: 'normal', wordBreak: 'break-all' }}><Info style={{ fontSize: 45, color: 'blue' }} /></TableCell>
                            <TableCell style={{ border: 'none', padding: 2, whiteSpace: 'normal', wordBreak: 'break-all' }}>
                                <Typography component="div" variant="h6" sx={{ fontSize: 16, paddingBottom: 1 }}>{theSectionTitle}</Typography>
                            </TableCell>
                        </TableRow>
                    </TableBody>
                </Table></TableContainer>
                <br />
                {theSectionDesc}
                <br /><br /><Button size="small" onClick={() => (window.location.href = LINK_TO_OUR_WEBSITE_HELP_CENTER)}>Open Help Center</Button>
            </>
        );
    }
}
export default HelpCenter;